import { render, staticRenderFns } from "./liveToBeReviewedPass.vue?vue&type=template&id=eb150650"
import script from "./liveToBeReviewedPass.vue?vue&type=script&lang=js"
export * from "./liveToBeReviewedPass.vue?vue&type=script&lang=js"
import style0 from "./liveToBeReviewedPass.vue?vue&type=style&index=0&id=eb150650&prod&lang=less&scope=true"
import style1 from "./liveToBeReviewedPass.vue?vue&type=style&index=1&id=eb150650&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports