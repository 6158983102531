<template>
  <div>
    <div class="operationControl searchboxfl">
      <div class="searchbox">
        <div title="直播标题" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width:7rem">直播标题:</span>
          <el-input
            v-model="liveTitle"
            type="text"
            size="small"
            placeholder="请输入直播标题"
            clearable
          />
        </div>
        <div title="提交机构" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width:7rem">提交机构:</span>
          <el-select
            size="small"
            v-model="compId"
            remote
            :remote-method="getCompanyList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in CompanyList"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </div>
         <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
            fixed
            width="100px"
          />
          <el-table-column
            label="直播标题"
            align="left"
            show-overflow-tooltip
            prop="liveTitle"
            fixed
            width="180"
          />
          <el-table-column
            label="主播微信号"
            align="left"
            show-overflow-tooltip
            prop="teacherWechatId"
              width="180"
          />
          <el-table-column
            label="主播昵称"
            align="left"
            show-overflow-tooltip
            prop="liveNickname"
          ><template slot-scope="scope">{{
              scope.row.liveNickname || "--"
            }}</template>
          </el-table-column>  
          <el-table-column
            label="直播开始时间"
            align="left"
            show-overflow-tooltip
            prop="startTime"
            width="150"
          />
          <el-table-column
            label="直播结束时间"
            align="left"
            show-overflow-tooltip
            prop="endTime"
            width="150"
          />
          <el-table-column
            label="直播间密码"
            align="left"
            show-overflow-tooltip
            prop="liveMmValue"
            width="150"
          />
          <el-table-column
            label="提交机构"
            align="left"
            show-overflow-tooltip
            prop="compName"
            width="250"
          />
          <el-table-column
            label="提交时间"
            align="left"
            show-overflow-tooltip
            prop="submitTime"
            width="140"
          >
            <template slot-scope="scope">{{
              scope.row.submitTime | moment
            }}</template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="150px"
            show-overflow-tooltip
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                @click="
                  doReview(
                    scope.row.liveId,
                    name,
                  )
                "
                >查看</el-button
              >
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "liveClassFestivalToBeReviewed",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      compId: "",
      names: "",
      liveTitle: "",
      CompanyList:[],
    };
  },
  computed: {},
  created() {
    this.init();
    this.getData()
  },
  methods: {
    init() {
      this.getTableHeight();
    },
    getData(pageNum, url, name,auditState) {
      console.log(name);
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        auditState:'40'
      };
      if (this.liveTitle) {
        params.liveTitle = this.liveTitle;
      }
       if (this.compId) {
        params.compId = this.compId;
      }
      url = "/room/miniapp-live-room/auditList";
      this.doFetch({
        url: url,
        params,
        pageNum,
      },true,2);
    },
    doReview(liveId,name) {
      this.$router.push({
        path: "/web/miniApp/liveReviewDetails",
        query: {
          getClassInfo: JSON.stringify({
            liveId,
            name:this.name,
          }),
           stu:'review'
        },
      });
    },
    //提交机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1 ;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  mounted: function() {},
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          if(val.query.active == 'three') {
            this.getData(-1,'/room/miniapp-live-room/auditList',val.query.active,'40');
          }
          if(val.query.active == 'second') {
            this.getData(-1,'/room/miniapp-live-room/auditList',val.query.active,'30');
          }
          if(val.query.active == 'first') {
            this.getData(-1,'/room/miniapp-live-room/auditList',val.query.active,'20');
          }
          
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
</style>
<style lang="less">
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
</style>
